import request from '@/utils/request';
import requesthttps from '@/utils/requesthttps';
export function getChatAis(data) {
  return requesthttps({
    url: '/chatAi/chatAi',
    method: 'post',
    data,
  });
}
//总览 获取使用时长  和平均压力值
export function getDotsTimeAndPressure(data) {
  return requesthttps({
    url: '/get-dotsv3/get_dots_infors',
    method: 'post',
    data,
  });
}
export function getDotsInfor(data) {
  return requesthttps({
    url: '/get-dotsv3/get_dots_infor',
    method: 'post',
    data,
  });
}
export function getItems(data) {
  return request({
    url: '/getitemsforplan',
    method: 'post',
    data,
  });
}
export function gotUserJoinAllStation(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/planUserResult/gotUserJoinAllStation',
    method: 'post',
    data,
  });
}
export function queryQuestionBaseTreeList(data) {
  return requesthttps({
    url: '/resourcePlant-web/api/console/markArea/queryQuestionBaseTreeList',
    method: 'post',
    data,
  });
}
export function realTimeAnswer(data) {
  return request({
    url: '/realTimeAnswer',
    method: 'post',
    data,
  });
}
export function getEboxStates(data) {
  return request({
    url: '/get_ebox_states',
    method: 'post',
    data,
  });
}
export function correction_in_class(data) {
  return requesthttps({
    url: '/auto-correct-out/correction_in_class',
    method: 'post',
    data,
  });
}

export function queryMacListByClassId(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryMacListByClassId',
    method: 'post',
    data,
  });
}
export function queryChapterLabelDetailList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/class/queryChapterLabelDetailList',
    method: 'post',
    data,
  });
}
export function queryErrorLabelBetweenTimeList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/user/queryErrorLabelBetweenTimeList',
    method: 'post',
    data,
  });
}
export function queryPlanQuestionUserSpendTimes(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/class/queryPlanQuestionUserSpendTimes',
    method: 'post',
    data,
  });
}
export function queryPlanCatalogDetailList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/class/queryPlanCatalogDetailList',
    method: 'post',
    data,
  });
}
export function queryPlanUserJoinRecords(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/queryPlanUserJoinRecords',
    method: 'post',
    data,
  });
}

export function queryChapterCatalogDetailList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/class/queryChapterCatalogDetailList',
    method: 'post',
    data,
  });
}
export function queryErrorCatalogBetweenTimeList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/user/queryErrorCatalogBetweenTimeList',
    method: 'post',
    data,
  });
}
export function queryGroupByStatusByMacs(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/penBox/queryGroupByStatusByMacs',
    method: 'post',
    data,
  });
}
export function queryPlanLabelDetailList(data) {
  return requesthttps({
    url: '/reportCenter-web/api/report/class/queryPlanLabelDetailList',
    method: 'post',
    data,
  });
}

export function save(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/planTechClass/save',
    method: 'post',
    data,
  });
}
export function queryQuestionTeachClass(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/planTechClass/queryQuestionTeachClass',
    method: 'post',
    data,
  });
}
