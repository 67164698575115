<template>
	<div>
		<div style="width: 100%;background-color: #FFFFFF;padding: 10px;text-align: left;margin-bottom: 20px;">
			<el-date-picker v-model="value1" value-format="timestamp" type="daterange" range-separator="至"
				:start-placeholder="$store.state.teachlanguagedata.startingtime" :end-placeholder="$store.state.teachlanguagedata.endtime">
			</el-date-picker>
		</div>
		<div class="Correct container">
			<div class="Correct_con row clearfix">
				<div class="Correct_con_left col-md-2">
					<div class="Correct_con_left_top">
						<el-select clearable filterable v-model="class_selected">
							<el-option v-for="option in options" :label="option.lable" :value="option.value">
								{{ option.lable }}
							</el-option>
						</el-select>
					</div>
					<div class="Correct_con_left_con">
						<div>
							<div class="check_user_type" v-for="item in corrected_users"
								:key="item.id" :style="{'color': (student_id==item.id ? '#3d8cff':'#000')}"
								v-on:click="handleclick(item)">
								<div v-if="'student'==item.type">
									<img src="../../assets/img/02/男生头像.png">
									<span>{{ item.name }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="Correct_con_right col-md-10">
					<div style="overflow: auto;	height: 78vh;">
						<div style="position: fixed;bottom: 40px;padding: 20px;">
							<div class="explain_right" @click="chonghui()" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.redraw}}
							</div>
							<div class="explain_right" v-on:click="shangyyiye" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.previouspage}}
							</div>
							<div class="explain_right" v-on:click="xiayiye" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.nextpage}}
							</div>
						</div>
						<div style="position: fixed;right: 60px;bottom: 60px;">
							<div class="explain_right" @click="chonghui()" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.redraw}}
							</div>
							<div class="explain_right" v-on:click="shangyyiye" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.previouspage}}
							</div>
							<div class="explain_right" v-on:click="xiayiye" style="margin-top: 20px;">
								{{$store.state.teachlanguagedata.nextpage}}
							</div>
						</div>
						<canvas id="canvas" :width="canvesWight" :height="canvesHeight"></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryMyClassList
	} from '../../api/base.js'
	import {
		queryUserListByClassId
	} from '../../api/notebook.js'
	import {
		getdotsv3,
		queryMacSingList
	} from '../../api/Correct.js'
	export default {
		name: 'Class',
		data() {
			return {
				options: '',
				class_selected: '',
				corrected_users: null,
				check_user_type: '',
				canvesWight: 1033.4 / 2,
				canvesHeight: 1461.6 / 2,
				value1: '',
				student_id: '',
				pageId: 1,
				canvesn: 1,
			}
		},
		watch: {
			class_selected(value) {
				this.queryuserListByClassId()
			},
			value1(data) {
				if (this.student_id) {
					this.getdotss()
				}
			},
			student_id(data) {
				if (this.value1) {
					this.getdotss()
				}
			}
		},
		mounted() {
			this.getbanji()
			this.init_canvas()
		},
		methods: {
			getdotss() {
				queryMacSingList({
					"userId": this.student_id
				}).then(res => {
					if(res.data.result&&res.data.result[0].penMac){
						let canshu = {
							"data": {
								"beginTime": this.value1[0],
								"endTime": this.value1[1]+86399000,
								"endX": 104,
								"endY": 297 / 2.032 + 1,
								"startX": 0,
								"startY": 0,
								"startPageId": this.pageId,
								"endPageId": this.pageId,
								"penMac": res.data.result[0].penMac
							}
						}
						getdotsv3(canshu).then(response => {
							if (response.data.msg == '该学生未绑定笔') {
								this.$message({
									message: '该学生未绑定笔',
									type: 'warning'
								});
							} else {
								if (response.data.Stroke.length > 0) {
									this.dotStroke = response.data.Stroke
									$("#canvas").removeLayer('remyBox').drawLayers()
									this.dots_draw1(this.dotStroke, '5');
								}else{
									this.dotStroke=''
									$("#canvas").removeLayer('remyBox').drawLayers()
								}
							}
						});
					}else{
						this.$message({
							message: '该学生未绑定笔',
							type: 'warning'
						});
					}
				})
			},
			dots_draw1(data, n) {
				$("#canvas").draw({
					layer: true,
					name: 'remyBox',
					fn: () => {
						data.forEach((datasitem, datasindex) => {
							let that = this;
							this.cxt.beginPath();
							this.cxt.lineWidth=2
							datasitem.x.forEach((datasitemitem, datasitemindex) => {
								if (datasitemindex == 0) {
									this.cxt.moveTo(datasitemitem * n / this.canvesn, datasitem
										.y[
											datasitemindex] * n / this.canvesn);
								} else {
									this.cxt.lineTo(datasitemitem * n / this.canvesn, datasitem
										.y[
											datasitemindex] * n / this.canvesn);
								}
							})
							this.cxt.stroke();
						})
					}
				}).drawLayers()
			},
			dots_draw2(data, n) {
				$("#canvas").draw({
					layer: true,
					name: 'myBox',
					fn: () => {
						data.forEach((datasitem, datasindex) => {
							let that = this;
							if (!this.remyBox) {
								(function(j) {
									setTimeout(() => {
										that.cxt.beginPath();
										datasitem.x.forEach((datasitemitem,
											datasitemindex) => {
												if (datasitemindex == 0) {
													that.cxt.moveTo(datasitemitem * n /
														that.canvesn, datasitem.y[
															datasitemindex] * n /
														that.canvesn);
												} else {
													that.cxt.lineTo(datasitemitem * n /
														that.canvesn, datasitem.y[
															datasitemindex] * n /
														that.canvesn);
												}
											})
										that.remyBox = 1
										that.cxt.stroke();
									}, 10 * j)
								})(datasindex)
							} else {
								if (!this.myBox) {
									$("#canvas").removeLayer('myBox').drawLayers()
									this.dots_draw1(this.dotStroke, '5')
									this.myBox = 1
								}
							}

						})
					}
				}).drawLayers()
			},
			//获取班级
			getbanji() {
				let data = {
					'userToken': JSON.parse(localStorage.getItem("teachuserToken")),
				}
				queryMyClassList(data).then(response=>{
					response.data.result.forEach(item=>{
						item.value=item.id
						item.lable=item.name
					})
					this.options = response.data.result
					this.class_selected = this.options[0].value
					this.queryuserListByClassId()
				})
			},
			queryuserListByClassId() {
				let data = {
					"id": this.class_selected,
					"pageSize": 1000,
					"currPage": 1
				}
				queryUserListByClassId(data).then((res) => {
					this.corrected_users = res.data.result.list
				})
			},
			chonghui() {
				$("#canvas").removeLayer('remyBox').drawLayers()
				this.remyBox = 0
				this.myBox = 0
				this.dots_draw2(this.dotStroke, '5')
			},
			shangyyiye() {
				if (this.pageId > 1) {
					this.pageId = this.pageId - 1
					$("#canvas").removeLayer('remyBox').drawLayers()
					this.dots_draw2()
				}
			},
			xiayiye() {
				if (this.pageId >= 0 && this.pageId <= 79) {
					this.pageId = this.pageId + 1
					$("#canvas").removeLayer('remyBox').drawLayers()
					this.getdotss()
				}
			},
			init_canvas: function() {
				const canvas = document.getElementById('canvas');
				this.cxt = canvas.getContext('2d');
				var img1 = new Image();
				img1.src = 'https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/notebook/notebook2.png'
				$('#canvas').drawImage({
					source: img1.src,
					layer: true,
					x: 0,
					y: 0,
					width: this.canvesWight,
					height: this.canvesHeight,
					fromCenter: false,
					shadowColor: '#222',
					shadowBlur: 3,
				}).drawLayers();
			},
			handleclick(data) {
				this.student_id = data.id
			}
		}
	}
</script>

<style>
	.Correct {
		background-color: #FFFFFF;
		width: 100%;
	}

	.Correct_con_left {
		text-align: left;
		border-right: 1px solid #deebff;
	}

	.Correct_con_left_top {
		padding: 20px;
		border-bottom: 1px solid #deebff;
	}

	.Correct_con_left_button {
		display: inline-block;
		width: 49%;
		height: 32px;
		color: #13264d;
		font-size: 14px;
		line-height: 32px;
		border-radius: 2px;
		text-align: center;
		cursor: pointer;
		background-color: #13264d;
		color: #fff;
	}

	.Correct_con_left_select {
		margin-top: 20px;
	}

	.Correct_con_left_con {
		margin-top: 20px;
		overflow: auto;
		height: 70vh;
	}

	.check_user_type {
		display: flex;
		margin-top: 20px;
		padding: 0 20px;
		justify-content: space-between;
	}

	.Correct_con_right {
		height: 78vh;
		position: relative;
	}
</style>
