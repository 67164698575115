import requesthttps from '@/utils/requesthttps'
export function queryUserListByClassId(data){
    return requesthttps({
        url:'/userPlant-web/api/console/class/queryUserListByClassId',
        method:'post',
        data
    })
}
export function queryTeachList(data){
    return requesthttps({
        url:'/userPlant-web/api/console/room_plan/queryTeachList',
        method:'post',
        data
    })
}
export function roomPlanSave(data){
    return requesthttps({
        url:'/userPlant-web/api/console/room_plan/save',
        method:'post',
        data
    })
}