<template>
  <div class="className" :style="{ width: fullScreenShow ? '100%' : '50%' }">
    <div class="className_con">
      <Ceshi
        ref="Ceshi"
        :canvasIdName="className"
        :selectionArea="selectionArea"
        :clickFun="clickFun"
        @studentWorkTime="studentWorkTime"
      ></Ceshi>
      <div class="className_con_bott">
        <div class="className_con_bott_left">
          <img
            style="margin-right: 8px;"
            v-if="classItem.state == 1"
            src="../assets/img/online.png"
          />
          <img
            style="margin-right: 8px;"
            v-if="classItem.state == 0"
            src="../assets/img/offline.png"
          />
          <span>{{ classItem.name }}</span>
        </div>
        <div class="className_con_bott_right">
          <span>学生答题时间：</span><span>{{ workTime }}</span
          ><span>秒</span>
        </div>
        <div class="className_con_bott_con">
          <span class="className_con_bott_con_span" @click="previousPage()"
            ><img src="../assets/img/previousPage.png"
          /></span>

          <span
            >{{ pictureSize == 'A3' ? (cur_page + 2) / 2 : cur_page + 1 }}/{{
              pictureSize == 'A3' ? img_list.length / 2 : img_list.length
            }}</span
          >
          <span class="className_con_bott_con_span" @click="nextPage()"
            ><img src="../assets/img/nextPage.png"
          /></span>
        </div>
        <div class="className_con_bott_right">
          <div>
            <span>学生心率：</span
            ><span style="width: 40px;">{{ studentHeartRate }}</span
            ><span>次/分钟</span>
          </div>
          <el-button @click="Repaint()" style="padding:5px 10px;"
            >{{ $store.state.teachlanguagedata.redraw }}
          </el-button>
          <el-button @click="fullScreen()" style="padding:5px 10px;"
            >{{ fullScreenShow ? '半屏' : '全屏' }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showLoading, hideLoading } from '../utils/Loading';
import { getCorrectionFromPlan } from '../api/Correct.js';
import { queryListByDictValue } from '../api/base.js';
import Revision from './Revision.vue';
import Ceshi from './Ceshi/index.vue';
export default {
  name: 'Class',
  data() {
    return {
      workTime: 0,
      studentHeartRate: '', //学生心率
      pageId: null,
      pageIds: null, //
      cur_page: 0, //第几页
      img: null, //图片地址
      userId: null, //用户Id
      img_list: [], //
      intercept: null,
      teachId: JSON.parse(localStorage.getItem('teachlogin')).id, //老师Id
      teachLoginData: JSON.parse(localStorage.getItem('teachlogin')),
      setId: JSON.parse(localStorage.getItem('teachsetId')),
      planId: JSON.parse(localStorage.getItem('teachplan_id')),
      classId: JSON.parse(localStorage.getItem('teachclass_id')),
      canvesWight: 800, //canvse 宽
      pictureSize: 'A3',
      planData: JSON.parse(localStorage.getItem('teachQuestionSetInformation')), //作业相关信息
      deviationStutes: null, //偏移状态 老师偏移还是学生偏移
      fullScreenShow: false, //是否点击全屏
      timer: null,
    };
  },
  props: ['className', 'classItem', 'selectionArea', 'clickFun'],
  components: {
    Ceshi,
  },
  watch: {
    className(val) {
      this.get_correction_from_plan();
    },
  },
  mounted() {
    console.log('className', this.className);
    this.intercept = this.$parent.intercept;
    this.get_correction_from_plan();
    clearInterval(this.timer);
    this.timer = null;
    this.studentHeartRate = Math.floor(Math.random() * (100 - 75 + 1)) + 75;
    this.setTimer();
  },
  destroyed: function() {
    // 每次离开当前界面时，清除定时器
    clearInterval(this.timer);

    this.timer = null;
  },
  methods: {
    //从yiye获取学生做题时间
    studentWorkTime(e) {
      this.workTime = e;
    },
    //心脏跳动变化
    setTimer() {
      if (this.timer == null) {
        let _this = this;
        this.timer = setInterval(() => {
          let num =
            _this.studentHeartRate +
            (Math.random() > 0.5 ? 1 : -1) *
              (Math.floor(Math.random() * (5 - 1 + 1)) + 1);
          if (num < 62) {
            _this.studentHeartRate = num + 8;
          } else if (num > 85) {
            _this.studentHeartRate = num - 8;
          } else {
            _this.studentHeartRate = num;
          }
        }, 7000);
      }
    },
    //点击让全屏
    fullScreen() {
      this.fullScreenShow = !this.fullScreenShow;
    },
    // 获取所有页面
    get_correction_from_plan(userId) {
      this.userId = this.classItem.id;
      getCorrectionFromPlan({
        plan_id: this.planId,
        userID: this.teachId, //要给的
        setId: this.setId,
        class_id: this.classId,
      }).then((response) => {
        this.corrected_users = response.data.corrected_users;
        this.unjoin_users = response.data.unjoin_users;
        this.pageId = response.data.pageId;
        this.pageIds = response.data.pageIds;
        this.img_list = response.data.img_list;
        this.img = response.data.img_src;
        if (this.img_list.length > 1 && this.img_list[0] == this.img_list[1]) {
          this.pictureSize = 'A3';
        } else {
          this.pictureSize = 'A4';
        }
        if (this.pictureSize == 'A3') {
          this.cur_page = this.ou(this.$parent.pageNo)
            ? this.$parent.pageNo - 2
            : this.$parent.pageNo - 1;
        } else {
          this.cur_page = this.$parent.pageNo
            ? this.$parent.pageNo - 1
            : response.data.cur_page;
        }
        this.$refs.Ceshi.init();
      });
    },
    // 判断奇偶
    ou(num) {
      //写出一个函数
      if (num % 2 == 0) {
        //条件判断  形参模上一个2余数为零
        return true; //如果为零返回true
      } else {
        return false; //如果不为零返回false
      }
    },
    //上一页
    previousPage() {
      this.pictureSize = this.$store.state.pictureSize;
      if (this.cur_page >= 1) {
        if (this.pictureSize == 'A3') {
          this.cur_page = this.cur_page - 2;
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
        } else {
          this.cur_page = this.cur_page - 1;
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
        }
      }
      this.$refs.Ceshi.previousPage();
    },
    //下一页
    nextPage() {
      this.pictureSize = this.$store.state.pictureSize;
      if (this.cur_page >= 0 && this.img_list.length >= this.cur_page + 2) {
        if (this.pictureSize == 'A3') {
          this.cur_page = this.cur_page + 2;
          if (this.pageIds[this.cur_page]) {
            this.pageId = this.pageIds[this.cur_page];
            this.img = this.img_list[this.cur_page];
          } else {
            this.cur_page = this.cur_page - 2;
          }
        } else {
          this.cur_page = this.cur_page + 1;
          this.pageId = this.pageIds[this.cur_page];
          this.img = this.img_list[this.cur_page];
        }
      }
      this.$refs.Ceshi.nextPage();
    },
    // 重绘
    Repaint() {
      this.$refs.Ceshi.Repaint();
    },
  },
};
</script>

<style lang="scss">
.fullScreen {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
}
.className {
  overflow: hidden;
}
.className_con {
  width: 100%;
  border: 2px solid #d8deea;
  background: #fff;

  .className_con_bott {
    display: flex;
    border-top: 1px solid #d8deea;
    padding: 10px 0;
    justify-content: space-around;

    .className_con_bott_con {
      display: flex;
      align-items: center;

      .className_con_bott_con_span {
        width: 24px;
        height: 24px;
        margin: 0 7px;
        display: inline-block;
        border: 2px solid #d8deea;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .className_con_bott_right {
      color: #8e9bb1;
    }
  }
}
</style>
