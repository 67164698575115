<template>
  <!-- style="display: flex;width:50%;flex-wrap: wrap;" 学生笔迹页面--学生列表-->
  <div class="dot" @click="lineshow = false">
    <!-- style="overflow: hidden;" -->
    <div class="dot_con" style="overflow: hidden;">
      <Correct
        v-for="item in linestatusindex"
        :ref="'className' + item.id"
        :key="'className' + item.id"
        :className="'className' + item.id"
        :classItem="item"
        :selectionArea="selectionArea"
        :clickFun="clickFun"
        class="correctClass"
      ></Correct>
    </div>
    <div class="dot_bottm">
      <div class="dot_bottm_top" @click.stop="" v-if="lineshow">
        <div class="dot_bottm_top_top">
          <div class="dot_bottm_top_top_left">
            <span
              v-if="online.length > 0"
              @click.stop="lineindex = '1'"
              :style="{ background: lineindex == '1' ? '#fff' : '' }"
              ><img
                style="margin-right: 8px;"
                src="../assets/img/online.png"
              />{{ $store.state.teachlanguagedata.connected }}</span
            >
            <span
              v-if="offline.length > 0"
              @click.stop="lineindex = '2'"
              :style="{ background: lineindex == '2' ? '#fff' : '' }"
              ><img
                style="margin-right: 8px;"
                src="../assets/img/offline.png"
              />{{ $store.state.teachlanguagedata.unconnected }}</span
            >
            <span
              v-if="unbound.length > 0"
              @click.stop="lineindex = '3'"
              :style="{ background: lineindex == '3' ? '#fff' : '' }"
              >{{ $store.state.teachlanguagedata.Penunboundservice }}</span
            >
            <span
              v-if="unrelation.length > 0"
              @click.stop="lineindex = '4'"
              :style="{ background: lineindex == '4' ? '#fff' : '' }"
              >{{ $store.state.teachlanguagedata.Noonetiedapen }}</span
            >
            <span style="display: flex;"
              >{{ $store.state.teachlanguagedata.Youcanonlychooseupto
              }}<el-input
                max="10"
                min="1"
                style="width:40px"
                v-model="nameSize"
              ></el-input
              >{{ $store.state.teachlanguagedata.studentN }}</span
            >
          </div>
          <div class="dot_bottm_top_top_right">
            <span
              class="dot_bottm_top_top_right_clear"
              @click.stop="tableClear(true)"
              >{{ $store.state.teachlanguagedata.empty }}</span
            >
            <span style="padding-right:16px;" @click.stop="lineshow = false"
              >X</span
            >
          </div>
        </div>
        <div class="dot_bottm_top_con">
          <div v-if="lineindex == '1'">
            <!-- :style="{ 'background': item.bgc ? '#DEEBFF' : '' }" -->
            <span
              class="spans"
              v-for="(item, index) in online"
              :key="index"
              @click.stop="tableName(item, index)"
              :style="{
                'background-color':
                  item.buge == 1
                    ? '#70F58B'
                    : item.buge == 0.5
                    ? '#FAFA6B'
                    : item.buge == 0
                    ? '#FF6666'
                    : item.buge == -2
                    ? '#7AFEFA'
                    : '#ccc',
                color: item.bgc ? '#409eff' : '',
              }"
            >
              {{ item.mobile ? item.mobile : item.name }}
            </span>
          </div>
          <!-- :class="item.resultJudgment==1?active1:item.resultJudgment==0.5?active2:item.resultJudgment==0?active1:null" -->
          <div v-if="lineindex == '2'">
            <span
              class="spans"
              v-for="(item, index) in offline"
              :key="index"
              @click.stop="tableName(item, index)"
              :style="{
                'background-color':
                  item.buge == 1
                    ? '#70F58B'
                    : item.buge == 0.5
                    ? '#FAFA6B'
                    : item.buge == 0
                    ? '#FF6666'
                    : item.buge == -2
                    ? '#7AFEFA'
                    : '#ccc',
                color: item.bgc ? '#409eff' : '',
              }"
            >
              {{ item.mobile ? item.mobile : item.name }}
            </span>
          </div>
          <div v-if="lineindex == '3'">
            <span
              class="spans"
              v-for="(item, index) in unbound"
              :key="index"
              :style="{
                'background-color':
                  item.buge == 1
                    ? '#70F58B'
                    : item.buge == 0.5
                    ? '#FAFA6B'
                    : item.buge == 0
                    ? '#FF6666'
                    : item.buge == -2
                    ? '#7AFEFA'
                    : '#ccc',
              }"
            >
              {{ item.mobile ? item.mobile : item.name }}
            </span>
          </div>
          <div v-if="lineindex == '4'">
            <span
              class="spans"
              v-for="(item, index) in unrelation"
              :key="index"
              :style="{
                'background-color':
                  item.buge == 1
                    ? '#70F58B'
                    : item.buge == 0.5
                    ? '#FAFA6B'
                    : item.buge == 0
                    ? '#FF6666'
                    : item.buge == -2
                    ? '#7AFEFA'
                    : '#ccc',
              }"
            >
              {{ item.mobile ? item.mobile : item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="dot_bottm_bottm">
        <div
          v-if="$store.state.rollShow"
          v-preventReClick
          @click.stop="getRollShow()"
          class="right_button"
        >
          滚动距离
        </div>
        <div
          v-else
          v-preventReClick
          @click.stop="getRollShow()"
          class="right_button"
        >
          滚动缩放
        </div>
        <div @click.stop="lineshow = !lineshow" class="right_button">
          {{ $store.state.teachlanguagedata.students }}
        </div>
        <div @click.stop="canves_show = !canves_show" class="right_button">
          {{ $store.state.teachlanguagedata.comments }}
        </div>
        <div
          v-if="teachexplainShow"
          @click.stop="previousPage"
          class="right_button"
        >
          {{ $store.state.teachlanguagedata.previouspage }}
        </div>
        <div
          v-if="teachexplainShow"
          @click.stop="nextPage"
          class="right_button"
        >
          {{ $store.state.teachlanguagedata.nextpage }}
        </div>
      </div>
    </div>
    <Board v-if="canves_show" v-on:close="close"></Board>
  </div>
</template>

<script>
import {
  getEboxStates,
  queryGroupByStatusByMacs,
  queryMacListByClassId,
} from '../api/Explain.js';
import { getCorrectionFromPlan } from '../api/Correct.js';
import Correct from './Correct1.vue';
import Board from './CanvasBoard/index.vue';
export default {
  data() {
    return {
      canves_show: false,
      img_listIndex: 0,
      class_id: localStorage.getItem('teachclass_id')
        ? JSON.parse(localStorage.getItem('teachclass_id'))
        : this.$store.state.teachclass.id,
      linestatusindex: [],
      linestatus: null,
      lineshow: true,
      lineindex: 1,
      offline: [],
      online: [],
      unbound: [],
      unrelation: [],
      teachQuestionSetInformation: {},
      nameSize: 2,
      list: [],
      // explainBgWidth:null
    };
  },
  computed: {
    changeMemberTab() {
      return this.$store.state.teach_val;
    },
  },
  props: [
    'selectionArea',
    'clickFun',
    'intercept',
    'pageNoShow',
    'teachexplainShow',
  ],
  watch: {
    changeMemberTab(val) {
      this.linestatusindex.forEach((item, index) => {
        let a = 'className' + index;
        this.$refs[a][0].get_correction_from_plan();
      });
    },
    nameSize(val) {
      this.$store.commit('getNameSize', val);
    },
  },
  beforeDestroy() {
    // console.log(this.offline, this.online);
    this.getArry(this.offline.concat(this.online));
  },
  components: {
    Correct,
    Board,
  },
  mounted() {
    // if(this.$route.path=='/explain'){
    //   this.explainBgWidth=true
    // }else{
    //   this.explainBgWidth=false
    // }
    console.log(this.$route.path);
    this.nameSize = this.$store.state.nameSize;
    this.img_listIndex = 0;
    this.teachplanIdType = JSON.parse(localStorage.getItem('teachplanIdType'));
    this.teachanswertype = JSON.parse(localStorage.getItem('teachanswertype'));
    this.teachQuestionSetInformation = JSON.parse(
      localStorage.getItem('teachQuestionSetInformation')
    );
    if (this.pageNoShow) {
      this.pageNo =
        localStorage.getItem('teach_val') &&
        JSON.parse(localStorage.getItem('teach_val')).questionAreaList
          ? JSON.parse(localStorage.getItem('teach_val')).questionAreaList[0]
              .pageNo
          : 1;
    } else {
      this.pageNo = 1;
    }
    this.initial();
  },
  methods: {
    // 添加数组
    getArry(data) {
      // console.log(data);
      let shuzu = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].bgc) {
          shuzu.push(data[i]);
        }
      }
      this.list = shuzu;
      localStorage.setItem('teachlist', JSON.stringify(this.list));
    },
    getRollShow() {
      this.$store.commit('getRollShow', !this.$store.state.rollShow);
    },
    // 批量上一页
    previousPage() {
      this.linestatusindex.forEach((item) => {
        let data = 'className' + item.id;
        this.$refs[data][0].previousPage();
      });
    },
    // 批量下一页
    nextPage() {
      this.linestatusindex.forEach((item) => {
        let data = 'className' + item.id;
        this.$refs[data][0].nextPage();
      });
    },
    //文字识别
    close(val) {
      this.canves_show = val;
    },
    initial() {
      this.linkStatus();
      this.get_correction_from_plan();
    },
    parentref() {
      this.$bus.$emit('addMethoc');
    },
    get_correction_from_plan() {
      getCorrectionFromPlan({
        plan_id: JSON.parse(localStorage.getItem('teachplan_id')),
        userID: JSON.parse(localStorage.getItem('teachuserID')), //这是要给的
        setId: JSON.parse(localStorage.getItem('teachsetId')),
        class_id: this.class_id, //这是要给的 1-4
      }).then((response) => {
        localStorage.setItem(
          'teachimg_list',
          JSON.stringify(response.data.img_list)
        );
        localStorage.setItem(
          'teachimg_list',
          JSON.stringify(response.data.img_list)
        );
        localStorage.setItem(
          'teachpageIds',
          JSON.stringify(response.data.pageIds)
        );
      });
    },
    tableClear(data) {
      this.linestatusindex = [];
      if (data) {
        this.online.forEach((items) => {
          delete items.bgc;
        });
        this.offline.forEach((items) => {
          delete items.bgc;
        });
      }
    },
    // 删除数组
    deleteArry(data) {
      if (this.online.indexOf(data) != -1) {
        this.online.forEach((items) => {
          if (items == data) {
            delete items.bgc;
          }
        });
      }
      if (this.offline.indexOf(data) != -1) {
        this.offline.forEach((items) => {
          if (items == data) {
            delete items.bgc;
          }
        });
      }
    },
    // 添加数组
    addArry(item, index) {
      if (this.online.indexOf(item) != -1) {
        this.online[index].bgc = true;
      }
      if (this.offline.indexOf(item) != -1) {
        // console.log(this.offline);
        // console.log(this.offline);
        this.offline[index].bgc = true;
      }
    },
    tableName(item, index) {
      if (
        this.findIndexByKeyValue(this.linestatusindex, 'name', item.name) == -1
      ) {
        if (this.linestatusindex.length > this.nameSize - 1) {
          this.deleteArry(this.linestatusindex[0]);
          this.linestatusindex.splice(0, 1);
          localStorage.setItem(
            'teachClassid' + item.id,
            JSON.stringify(this.img_listIndex)
          );
          // localStorage.removeItem('teachClassid'+this.linestatusindex[0].id)
          this.addArry(item, index);
          this.linestatusindex.push(item);
        } else {
          this.addArry(item, index);
          this.linestatusindex.push(item);
          localStorage.setItem(
            'teachClassid' + item.id,
            JSON.stringify(this.img_listIndex)
          );
        }
      } else {
        let n;
        for (let i = 0; i < this.linestatusindex.length; i++) {
          if (this.linestatusindex[i].id == item.id) {
            n = i;
          }
        }
        this.deleteArry(this.linestatusindex[n]);
        localStorage.removeItem('teachClassid' + item.id);
        this.linestatusindex.splice(n, 1);
      }
    },
    findIndexByKeyValue(arr, key, valuetosearch) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] == valuetosearch) {
          return i;
        }
      }
      return -1;
    },
    studentList(studentList) {
      if (
        this.$store.state.StudentList.students_list &&
        this.$store.state.StudentList.students_list.length > 0
      ) {
        //作图题  默认值
        studentList.map((offlineItem) => {
          offlineItem.buge = -1;
          this.$store.state.StudentList.students_list.forEach((item) => {
            if (offlineItem.name == item) {
              offlineItem.buge = -2;
            }
          });
        });
      } else {
        let that = this;
        let qweerrt = studentList;
        console.log(qweerrt);
        studentList.map((offlineItem) => {
          console.log('90909090:', that.$store.state.StudentList[0]);
          //先给每个学生一个-1属性
          offlineItem.buge = -1;
          if (
            that.$store.state.StudentList[0].halfRightStudent ||
            that.$store.state.StudentList[0].RightStudent ||
            that.$store.state.StudentList[0].wrongStudent
          ) {
            if (that.$store.state.StudentList[0].RightStudent.length > 0) {
              that.$store.state.StudentList[0].RightStudent.forEach((item) => {
                if (offlineItem.name == item) {
                  offlineItem.buge = 1;
                }
              });
            }
            if (that.$store.state.StudentList[0].wrongStudent.length > 0) {
              that.$store.state.StudentList[0].wrongStudent.forEach((item) => {
                if (offlineItem.name == item) {
                  offlineItem.buge = 0;
                }
              });
            }

            if (that.$store.state.StudentList[0].halfRightStudent.length > 0) {
              //that.$store.state.StudentList.halfRightStudent这个数组这个名字出现几次offlineItem.halfresultRight就是几
              that.$store.state.StudentList[0].halfRightStudent.forEach(
                (item) => {
                  if (offlineItem.name == item) {
                    offlineItem.buge = 0.5;
                  }
                }
              );
            }
          }
        });
      }
    },
    linkStatus() {
      //1-3
      let that = this;
      let data = {
        classIds: [this.class_id],
        planId: JSON.parse(localStorage.getItem('teachplan_id')),
      };
      //下面都是点做作业放本地的
      if (this.teachQuestionSetInformation.roomId) {
        data.startTime = this.teachQuestionSetInformation.beginTime;
        data.endTime = this.teachQuestionSetInformation.endTime;
      }
      queryMacListByClassId(data).then((res) => {
        let penBoxdata = {
          penBoxMacList: [],
        };
        let penBoxlist = [];
        res.data.result.forEach((item) => {
          if (item.penMac) {
            let penBoxarrny = {
              penmac: '',
              name: '',
              id: '',
            };
            penBoxdata.penBoxMacList.push(item.penMac);
            penBoxarrny.pen_mac = item.penMac;
            penBoxarrny.name = item.name;
            penBoxarrny.id = item.id;
            penBoxarrny.mobile = item.mobile;
            penBoxlist.push(penBoxarrny);
          } else {
            let penBoxarrny = {
              name: '',
              id: '',
            };
            penBoxarrny.name = item.name;
            penBoxarrny.id = item.id;
            penBoxarrny.mobile = item.mobile;
            this.unrelation.push(penBoxarrny);
          }
        });
        queryGroupByStatusByMacs(penBoxdata).then((res) => {
          for (let key in res.data.result) {
            this[key] = res.data.result[key];

            if (this[key].length > 0) {
              for (let i = 0; i < this[key].length; i++) {
                for (let j = 0; j < penBoxlist.length; j++) {
                  if (penBoxlist[j].pen_mac == this[key][i].mac) {
                    this[key][i].pen_mac = penBoxlist[j].pen_mac;
                    this[key][i].name = penBoxlist[j].name;
                    this[key][i].id = penBoxlist[j].id;
                    this[key][i].mobile = penBoxlist[j].mobile;
                  }
                }
              }
            }
          }
          // console.log(that.offline);
          // console.log(that.$store.state.StudentList);
          if (
            that.$store.state.StudentList &&
            that.$store.state.StudentList.length !== 0
          ) {
            if (that.online && that.online.length > 0) {
              //给学生加颜色属性
              that.studentList(that.online);
            }
            if (that.offline && that.offline.length > 0) {
              that.studentList(that.offline);

              // console.log(that.offline);
            }
            if (that.unbound && that.unbound.length > 0) {
              that.studentList(that.unbound);
            }
            if (that.unrelation && that.unrelation.length > 0) {
              that.studentList(that.unrelation);
            }
          }
          if (this.online && this.online.length > 0) {
            this.lineindex = 1;
          } else {
            if (this.offline && this.offline.length > 0) {
              this.lineindex = 2;
            } else {
              if (this.unbound && this.unbound.length > 0) {
                this.lineindex = 3;
              } else {
                if (this.unrelation && this.unrelation.length > 0) {
                  this.lineindex = 4;
                }
              }
            }
          }
          let teachlist = localStorage.getItem('teachlist')
            ? JSON.parse(localStorage.getItem('teachlist'))
            : false;
          if (teachlist && teachlist.length > 0) {
            for (let i = 0; i < this.online.length; i++) {
              for (let j = 0; j < teachlist.length; j++) {
                if (teachlist[j].id == this.online[i].id) {
                  this.tableName(this.online[i], i);
                }
              }
            }
            for (let i = 0; i < this.offline.length; i++) {
              for (let j = 0; j < teachlist.length; j++) {
                if (teachlist[j].id == this.offline[i].id) {
                  this.tableName(this.offline[i], i);
                }
              }
            }
          } else {
            if (this.online.length > 0) {
              for (let i = 0; i < this.online.length; i++) {
                if (i < 2) {
                  this.tableName(this.online[i], i);
                }
              }
            } else if (this.offline.length > 0) {
              for (let i = 0; i < this.offline.length; i++) {
                if (i < 2) {
                  this.tableName(this.offline[i], i);
                }
              }
            }
          }
          this.$forceUpdate();
        });
      });
    },
  },
};
</script>
<style lang="scss">
.correctClass {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  overflow: auto;
}
.spans {
  border-radius: 16px;
  min-width: 60px;
  text-align: center !important;
}

.Correct {
  display: flex;
  flex-wrap: wrap;
  // width: 50%;
  margin: 10px 0;
}

.active1 {
  background: green;
}

.active2 {
  background: yellow;
}

.active3 {
  background: red;
}
.explain {
  width: 50%;
}
// .right_button{
//   border: 1px solid #5D94E6 !important;

//   border-radius: 8px !important
// }
</style>
