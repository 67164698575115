<template>
	<div class="Correct container">
		<div class="Correct_con">
			<div class="Correct_con_left col-md-2">
				<div class="Correct_con_left_top">
					<!-- <span style="display: none;" class="Correct_con_left_button">学生</span><br /> -->
					<el-select clearable filterable class="Correct_con_left_select" v-model="check_user_type">
						<!-- <el-option v-for="(item,index) in ['学生笔记','老师笔记']" :key="item" :label="item" :value="item"> -->
						<el-option v-for="(item,index) in ['学生笔记']" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</div>
				<div class="Correct_con_left_con">
					<div class="check_user_type" v-for="item in queryUserListByClassIdData.list"
						:key="item.id" :style="{'color': (student_id==item.id ? '#3d8cff':'#000')}"
						@click="student_id=item.id">
						<div style="display: flex;">
							<img src="../../assets/img/02/男生头像.png">
							<span>{{ item.name }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="Correct_con_right col-md-10 height85vh">
				<div style="overflow: auto;" class="height85vh">
					<div style="position: fixed;bottom: 40px;padding: 20px;">
						<div class="explain_right" @click="GotUserSetPointsData()" style="margin-top: 20px;">
							下载笔记
						</div>
						<div class="explain_right" @click="writingInterfaceshow=true" style="margin-top: 20px;">
							学生笔迹
						</div>
						<div class="explain_right" @click="ShareNotesShowFun" style="margin-top: 20px;">
							分享笔记
						</div>
						<div class="explain_right" @click="shangyyiye" style="margin-top: 20px;">
							{{$store.state.teachlanguagedata.previouspage}}
						</div>
						<div class="explain_right" @click="xiayiye" style="margin-top: 20px;">
							{{$store.state.teachlanguagedata.nextpage}}
						</div>
					</div>
					<div style="position: fixed;right: 60px;bottom: 60px;">
						<div class="explain_right" @click="GotUserSetPointsData()" style="margin-top: 20px;">
							下载笔记
						</div>
						<div class="explain_right" @click="writingInterfaceshow=true" style="margin-top: 20px;">
							学生笔迹
						</div>
						<div class="explain_right" @click="ShareNotesShowFun" style="margin-top: 20px;">
							分享笔记
						</div>
						<div class="explain_right" @click="shangyyiye" style="margin-top: 20px;">
							{{$store.state.teachlanguagedata.previouspage}}
						</div>
						<div class="explain_right" @click="xiayiye" style="margin-top: 20px;">
							{{$store.state.teachlanguagedata.nextpage}}
						</div>
					</div>
					<Ceshi ref="Ceshi" :canvasIdName="className" :selectionArea="selectionArea" :clickFun="clickFun"></Ceshi>
				</div>
			</div>
		</div>
		<!-- 分享笔记 -->
		<el-dialog :visible.sync="ShareNotesShow">
			<el-radio-group v-model="radio1">
				<el-radio-button label="班级"></el-radio-button>
				<el-radio-button label="个人"></el-radio-button>
			</el-radio-group>
			<div>
				<el-form>
					<el-form-item v-if="radio1=='班级'" label="班级">
						<template>
							<el-select clearable filterable  v-model="classList" value-key="id">
								<el-option v-for="option in options" :label="option.name" :value="option">
									{{ option.name }}
								</el-option>
							</el-select>
						</template>
					</el-form-item>
					<el-form-item v-if="radio1=='个人'" label="个人">
						<template>
							<el-select clearable filterable v-model="classList" value-key="id">
								<el-option v-for="option in queryStudentListByClassIdData.list" :label="option.name" :value="option">
									{{ option.name }}
								</el-option>
							</el-select>
						</template>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ShareNotesShow = false">{{$store.state.teachlanguagedata.cancel}}</el-button>
				<el-button type="primary" @click="ShareSave()">{{$store.state.teachlanguagedata.confirm}}</el-button>
			</div>
		</el-dialog>
		<!-- 书写界面 -->
		<el-dialog :visible.sync="writingInterfaceshow" width="90%" class="my-info-dialog" @close='closeDialog'>
			<dotcan v-if="writingInterfaceshow" ref="dotimg" style="height:95vh;" :selectionArea="selectionArea" :clickFun="clickFun"></dotcan>
		</el-dialog>
	</div>
</template>

<script>
	import {
		queryUserListByClassId,
		gotUserSetPointsData,
		downLoadPointPdf,
		shareSave,
		queryMyClassList
	} from '../../api/base.js'
	import {
		queryMacListByClassId
	} from '../../api/Explain.js'
	import {
		queryDetailById,
	} from '../../api/Collect.js'
	import {
		getCorrectionFromPlan
	} from '../../api/Correct.js'
	import dotcan from '../../components/dot.vue'
	import Ceshi from '../../components/Ceshi/index.vue'
import { json } from 'body-parser'
	export default {
		name: 'Class',
		data() {
			return {
				className:'className',
				selectionArea:true,
				clickFun:true,
				writingInterfaceshow:false,
				radio1: '班级',
				classList: '',
				renderComponent: true,
				check_user_type: '学生笔记',
				ShareNotesShow: false,
				corrected_users: [],
				queryStudentListByClassIdData:{},
				queryUserListByClassIdData: {
					pageSize: 1000,
					currPage: 1
				},
				img_list_index: 0,
				img_list: [],
				pageIds: [],
				student_id: '',
				canvesWight: 1033.4,
				canvesHeight: 1461.6,
				classItem: '',
				options: null,
				classData: {},
				userId:null,
				planId:JSON.parse(localStorage.getItem('teachNote')).id,
				teachId:JSON.parse(localStorage.getItem('teachlogin')).id,
				setId:null,
				planData:JSON.parse(localStorage.getItem('teachNote')),
				classId:JSON.parse(localStorage.getItem('teachclass_id')),
				teachQuestionSetInformation:{},
			}
		},
		components: {
			dotcan,Ceshi
		},
		watch: {
			check_user_type(val) {
				this.QueryUserListByClassId()
			},
			student_id(val) {
				this.userId=val
				this.get_correction_from_plan()
			},
			radio1(val) {
				this.classList=null
				if (val == '班级') {
					this.getbanji()
				}else{
					this.QueryUserListByClassId(true)
				}
			}
		},
		mounted() {
			this.setId=this.$Download.getQuestionSet(JSON.parse(localStorage.getItem('teachNote')).resourceRelationList,'questionSet')
			this.teachQuestionSetInformation=JSON.parse(localStorage.getItem('teachQuestionSetInformation'))
			this.QueryUserListByClassId()
			this.QueryDetailById()
			this.QueryMacListByClassId()
			this.getbanji()
			this.get_correction_from_plan()
		},
		destroyed() {
			
		},
		methods: {
			get_correction_from_plan() {
				getCorrectionFromPlan({
					"plan_id": this.planId,
					"userID": this.teachId,
					"setId": this.setId,
					"class_id": this.classId
				}).then(response => {
					this.corrected_users = response.data.corrected_users;
					this.unjoin_users = response.data.unjoin_users;
					this.pageId = response.data.pageId;
					this.pageIds = response.data.pageIds;
					this.cur_page = response.data.cur_page;
					this.img_list = response.data.img_list;
					this.img = response.data.img_src;
					if(this.img_list.length>1&&this.img_list[0]==this.img_list[1]){
						this.pictureSize='A3'
					}else{
						this.pictureSize='A4'
					}
					this.$refs.Ceshi.init()
				})
			},
			closeDialog() {
				this.$refs.dotimg.clearwekcoket()
			},
			ShareNotesShowFun(){
				if(!this.student_id){
					this.$message('请选择分享学生')
					return
				}
				this.ShareNotesShow=true
			},
			//分享接口
			ShareSave() {
				let that =this
				let data = {
					"beginTime": JSON.parse(localStorage.getItem('teachNote')).beginTime,
					"endTime": JSON.parse(localStorage.getItem('teachNote')).endTime,
					"kind": 'notes',
					"relationId": JSON.parse(localStorage.getItem('teachNote')).id,
					"relationKind": JSON.parse(localStorage.getItem('teachNote')).planKind,
					"title": JSON.parse(localStorage.getItem('teachNote')).name,
					"sharerId": this.student_id,
					"sharerName": this.PropertyReturnObject(this.queryUserListByClassIdData.list, 'id', this.student_id)[0].name,
					"receiverId": this.classList.id,
					"receiverKind": this.classList.classKind?this.classList.classKind:this.classList.type,
					"receiverName": this.classList.name,
				}
				shareSave(data).then(res => {
					if(res.data.code==='0000'){
						that.ShareNotesShow=false
						that.$message('分享成功')
					}
					
				})
			},
			getbanji() {
				queryMyClassList({}).then(response => {
					this.options = response.data.result
				})
			},
			// 下载笔记
			GotUserSetPointsData() {
				let data = {
					planId: JSON.parse(localStorage.getItem('teachNote')).id,
					userId: this.student_id,
					setId: this.setId,
					userToken: JSON.parse(localStorage.getItem('teachuserToken')),
				}
				gotUserSetPointsData(data).then(res => {
					let pdfVoListShow=false
					res.data.result.pdfVoList.forEach(item=>{
						if(item.allDotList&&item.allDotList.length>0){
							pdfVoListShow=true
						}
					})
					if(pdfVoListShow){
						this.DownLoadPointPdf(res.data.result)
					}else{
						this.$message.error("这名学生暂无笔记")
					}
				})
			},
			DownLoadPointPdf(data) {
				downLoadPointPdf(data).then(res => {
					let imgUrl
					if(res.data.data.indexOf('http')==-1){
						imgUrl = "https://api.youcun.tech/epen-slice-upload/" + res.data.data
						this.DownloadProcessingPDF(imgUrl)
					}else{
						imgUrl =res.data.data
						this.DownloadProcessing(imgUrl)
					}
				})
			},
			DownloadProcessing (data){
				let aLink = document.createElement('a');
				aLink.href = data;
				aLink.style.display = 'none';
				document.body.appendChild(aLink);
				aLink.click();
				document.body.removeChild(aLink)
			},
			DownloadProcessingPDF(data) {
				var ajax = new XMLHttpRequest();
				ajax.open("GET", data, true);
				// ajax.setRequestHeader("Cache-Control", "no-cache")
				// ajax.setRequestHeader('Access-Control-Allow-Origin', '*');
				ajax.responseType = 'blob';
				ajax.onload = e => {
					let res = e.target.response
					let blob = new Blob([res], {
						type: "application/pdf;charset=UTF-8"
					}) // 这里的res为后端返回的流数据
					let aLink = document.createElement("a")
					let name = new Date().getTime()
					aLink.download = name + '.pdf' // 下载文件的名字
					aLink.href = URL.createObjectURL(blob)
					aLink.click()
				}
				ajax.send()
			},
			renderComponentFun(data) {
				this.renderComponent = false;
				this.$nextTick(() => {
					this.renderComponent = true;
				});
			},
			xiayiye() {
				this.$refs.Ceshi.nextPage()
			},
			shangyyiye() {
				this.$refs.Ceshi.previousPage()
			},
			//当前题集信息
			QueryDetailById() {
				let data = {
					setId: this.setId,
					// setId: 2364368931241844,
				}
				let formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				queryDetailById(formData).then(res => {
					this.queryDetailByIdData = res.data.result
					if (this.queryDetailByIdData.epenCodeList) {
						this.img_list = this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList,
							'inputUrl')
						this.pageIds = this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList, 'pageId')
						this.classData.Image = this.img_list[this.img_list_index]
					} else {
						this.classData.Image = null
						this.pageIds = [0]
					}
				})
			},
			// arry 对象 Attribute 属性
			AttributeVariableArray(arry, Attribute) {
				let images = []
				arry.forEach(item => {
					if (images.indexOf(item[Attribute]) == '-1') {
						images.push(item[Attribute])
					}
				})
				return images
			},
			QueryMacListByClassId() {
				let data = {
					"classIds": [JSON.parse(localStorage.getItem('teachclass_id'))],
					"planId": this.planId
				}
				if(this.teachQuestionSetInformation.roomId){
					data.startTime=this.teachQuestionSetInformation.beginTime
					data.endTime=this.teachQuestionSetInformation.endTime
				}
				queryMacListByClassId(data).then(res => {
					this.queryMacListByClassId = res.data.result
				})
			},
			QueryUserListByClassId(stutes) {
				let data = {
					"id": JSON.parse(localStorage.getItem("teachclass_id")),
					"pageSize": this.queryUserListByClassIdData.pageSize,
					"fromId": JSON.parse(localStorage.getItem("teachschoolID")),
					"currPage": this.queryUserListByClassIdData.currPage,
					relationKind: this.check_user_type == '学生笔记'||stutes? 'student' : 'teacher'
				}
				queryUserListByClassId(data).then(res => {
					if(stutes){
						this.queryStudentListByClassIdData = res.data.result
					}else{
						this.queryUserListByClassIdData = res.data.result
					}
				})
			},
			//根据对象一个属性返回数组中一个对象
			PropertyReturnObject(arry, attribute, value) {
				let canshu = arry.filter((item, index) => {
					return item[attribute] == value
				})
				return canshu
			},
		}
	}
</script>

<style>
	.Correct {
		background-color: #FFFFFF;
	}


	.Correct_con_left {
		text-align: left;
		border-right: 1px solid #deebff;
	}

	.Correct_con_left_top {
		padding: 20px 10px;
		border-bottom: 1px solid #deebff;
	}

	.Correct_con_left_button {
		display: inline-block;
		width: 49%;
		height: 32px;
		color: #13264d;
		font-size: 14px;
		line-height: 32px;
		border-radius: 2px;
		text-align: center;
		cursor: pointer;
		background-color: #13264d;
		color: #fff;
	}

	.Correct_con_left_select {
		margin-top: 20px;
	}

	.Correct_con_left_con {
		margin-top: 20px;
		overflow: auto;
		height: 70vh;
	}

	.check_user_type {
		display: flex;
		margin-top: 20px;
		padding: 0 20px;
		justify-content: space-between;
	}

	.Correct_con_right {
		position: relative;
	}

	.explain_chick {
		background-color: #f1f1f1;
	}
</style>
